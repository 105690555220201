import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faHome,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { useParams } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import moment from "moment-timezone";

const OrderForm = (props) => {
  const [formData, setFormData] = useState({});
  const [startPointData, setStartPointData] = useState("");
  const [endPointData, setEndPointData] = useState("");
  const [tariffsData, setTariffsData] = useState([]);
  const [driversData, setDriversData] = useState([]);
  const [errors, setErrors] = useState({
    tariff_id: "Оберіть тариф",
    client_phone: "Заповніть поле",
    drivers: "Оберіть водія",
    start_point_input: "Оберіть початкову точку",
    end_point_input: "Оберіть кінцеву точку",
  });
  const [checkedDriver, setCheckedDriver] = useState([]);
  const [selectedTariff, setSelectedTariff] = useState({});
  const distanceInputRef = useRef(null);
  const priceInputRef = useRef(null);

  let apiUrl = "https://api-taxi.logicart.dev/api/";

  const GOOGLE_API_KEY = "AIzaSyDVrcgV_Y79bMzfZHJGGfWW1xdUVklNd14";

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    fetch("https://api-taxi.logicart.dev/api/tariffs", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setTariffsData(result.tariffs);
      })
      .catch((error) => console.log("error", error));

    fetch("https://api-taxi.logicart.dev/api/driverlogins?status=active", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setDriversData(result.driver_logins);
      })
      .catch((error) => console.log("error", error));
  }, []);

  let id = null;
  let pageData = {};
  if (props.mode === "add") {
    pageData = {
      heading: "Додавання нової заявки",
      submitText: "Додати",
    };
    apiUrl += "order";
  } else if (props.mode === "edit") {
    id = params.id;
    pageData = {
      heading: "Редагування заявки",
      submitText: "Оновити",
    };
    apiUrl += "order/" + id;
  }

  useEffect(() => {
    if (props.mode === "edit") {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
      );

      fetch(apiUrl, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          setFormData(result.order);
          setErrors({});
        })
        .catch((error) => console.log("error", error));
    }
  }, []);

  const handleChange = (e) => {
    switch (e.target.name) {
      case "tariff_id":
        if (!e.target.value || e.target.value === "default") {
          setErrors({ ...errors, tariff_id: "Оберіть тариф" });
        } else {
          delete errors.tariff_id;
          setSelectedTariff(
            tariffsData.find(
              (tariff) => parseInt(tariff.id) === parseInt(e.target.value)
            )
          );
          setErrors({ ...errors });
        }
        break;
      case "client_phone":
        if (!e.target.value.length) {
          setErrors({
            ...errors,
            client_phone: "Номер телефону має заповнений",
          });
        } else {
          delete errors.client_phone;
          setErrors({ ...errors });
        }
        break;
      case "drivers":
        let updatedList = [...checkedDriver];
        if (e.target.checked) {
          updatedList = [...checkedDriver, e.target.value];
        } else {
          updatedList.splice(checkedDriver.indexOf(e.target.value), 1);
        }
        setCheckedDriver(updatedList);

        if (updatedList.length === 0) {
          setErrors({ ...errors, drivers: "Оберіть водія" });
        } else {
          delete errors.drivers;
          setErrors({ ...errors });
        }
        break;
      default:
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const errorsMsg = {
    //     'login' : 'Логін вже використовується',
    //     'phone_number' : 'Номер телефону вже використовується',
    //     'fullname' : "Ім'я",
    //     'email' : 'Адреса вже використовується',
    //     'role' : 'Оберіть роль',
    // }

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    let sendFormData = new FormData();
    sendFormData.append("client_phone", e.target.client_phone.value);
    sendFormData.append("start_point", startPointData);
    sendFormData.append("finish_point", endPointData);
    sendFormData.append("tariff_id", e.target.tariff_id.value);
    sendFormData.append("distance", distanceInputRef.current.value);
    sendFormData.append("price", priceInputRef.current.value);
    sendFormData.append("delay_minutes", e.target.delay_minutes.value);
    sendFormData.append("status_id", "1");
    // if ( checkedDriver.length === 1 ) {
    //     sendFormData.append('driver_id', checkedDriver[0]);
    // } else {
    //     sendFormData.append('driver_id', checkedDriver.join(','));
    // }
    sendFormData.append("request_time", moment().format("YYYY-MM-DD hh:mm:ss"));

    const sendOrderRequest = await fetch(apiUrl, {
      method: "POST",
      headers: myHeaders,
      body: sendFormData,
      redirect: "follow",
    })
      .then((result) =>
        result.json().then((data) => ({ status: result.status, body: data }))
      )
      .then((result) => {
        if (result.status === 200) {
          return result.body;
        } else {
          if (result.body.errors) {
            // for (const [key] of Object.entries(result.body.errors)) {
            //     setErrors({ ...errors, [key]: errorsMsg[key] });
            // }
          }
        }
      })
      .catch((error) => console.log("error", error));

    let orderAssignmentFormData = new FormData();
    orderAssignmentFormData.append("order_id", sendOrderRequest.id);
    if (checkedDriver.length === 1) {
      orderAssignmentFormData.append("driver_id", checkedDriver[0]);
    } else {
      orderAssignmentFormData.append("driver_id", checkedDriver.join(","));
    }

    await fetch("https://api-taxi.logicart.dev/api/orderassignment", {
      method: "POST",
      headers: myHeaders,
      body: orderAssignmentFormData,
      redirect: "follow",
    })
      .then((result) =>
        result.json().then((data) => ({ status: result.status, body: data }))
      )
      .then((result) => {
        if (result.status === 200) {
          navigate(`/orders/active`);
        } else {
          if (result.body.errors) {
            // for (const [key] of Object.entries(result.body.errors)) {
            //     setErrors({ ...errors, [key]: errorsMsg[key] });
            // }
          }
        }
      })
      .catch((error) => console.log("error", error));
  };

  const calculatePrice = (tariff, distance) => {
    const minPrice = tariff.minimal_price;
    let currentPrice = tariff.price_per_km * distance + tariff.start_price;
    return (currentPrice < minPrice ? minPrice : currentPrice).toFixed(2);
  };

  const showPriceAndDictance = () => {
    if (
      startPointData.trim().length !== 0 &&
      endPointData.trim().length !== 0 &&
      Object.keys(selectedTariff).length !== 0
    ) {
      let distanceUrl =
        "https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/distancematrix/json?origins=" +
        startPointData +
        "&destinations=" +
        endPointData +
        "&units=metric&key=" +
        GOOGLE_API_KEY;
      //https://cors-anywhere.herokuapp.com/
      fetch(distanceUrl, {
        method: "GET",
        headers: {
          "access-control-allow-origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          let distance = result.rows[0].elements[0].distance.value / 1000;
          distanceInputRef.current.value = distance.toFixed(2);
          priceInputRef.current.value = calculatePrice(
            selectedTariff,
            distance
          );
        })
        .catch((error) => console.log("error", error));
    }
  };

  const handleStartPoint = (data) => {
    delete errors.start_point_input;
    setErrors({ ...errors });
    setStartPointData(data.value.description);
  };

  const handleEndPoint = (data) => {
    delete errors.end_point_input;
    setErrors({ ...errors });
    setEndPointData(data.value.description);
  };

  useEffect(() => {
    showPriceAndDictance();
  }, [startPointData, endPointData, selectedTariff]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Volt</Breadcrumb.Item>
            <Breadcrumb.Item active>{pageData.heading}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>
            {pageData.heading} {formData?.login}
          </h4>
          <p className="mb-0">Your web analytics dashboard template.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button variant="primary" size="sm" className="mr-1">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            add
          </Button>
          <ButtonGroup className="ms-2 ms-lg-3 btn-group">
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10{" "}
                  <span className="icon icon-small ms-auto">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Пункт відправлення</Form.Label>
          <GooglePlacesAutocomplete
            selectProps={{
              startPointData,
              onChange: handleStartPoint,
              placeholder: "Пункт відправлення...",
              name: "start-point-input",
            }}
            apiOptions={{ language: "uk_UA", region: "uk_UA" }}
            debounce={500}
            apiKey={GOOGLE_API_KEY}
          />
          {errors.start_point_input && (
            <div className="invalid-feedback simple-text">
              {errors.start_point_input}
            </div>
          )}
        </Form.Group>
        {/*<Form.Group className="mb-3">*/}
        {/*    <Form.Label>Пункт відправлення</Form.Label>*/}
        {/*    <Form.Control isInvalid={errors.start_point} isValid={!errors.start_point} required defaultValue={formData?.start_point ? formData.login : '' } key="start_point" name="start_point" type="text" placeholder="Пункт відправлення" onChange={(e) => {handleChange(e)}} />*/}
        {/*    {errors.start_point && <Form.Control.Feedback isInvalid type="invalid">{errors.start_point}</Form.Control.Feedback>}*/}
        {/*</Form.Group>*/}
        <Form.Group className="mb-3">
          <Form.Label>Пункт прибуття</Form.Label>
          <GooglePlacesAutocomplete
            selectProps={{
              startPointData,
              onChange: handleEndPoint,
              placeholder: "Пункт прибуття...",
              name: "end-point-input",
            }}
            apiOptions={{ language: "uk_UA", region: "uk_UA" }}
            debounce={500}
            apiKey={GOOGLE_API_KEY}
          />
          {errors.end_point_input && (
            <div className="invalid-feedback simple-text">
              {errors.end_point_input}
            </div>
          )}
        </Form.Group>
        {/*<Form.Group className="mb-3">*/}
        {/*    <Form.Label>Пункт прибуття</Form.Label>*/}
        {/*    <Form.Control isInvalid={errors.finish_point} isValid={!errors.finish_point} required defaultValue={formData?.finish_point ? formData.login : '' } key="finish_point" name="finish_point" type="text" placeholder="Пункт прибуття" onChange={(e) => {handleChange(e)}} />*/}
        {/*    {errors.finish_point && <Form.Control.Feedback isInvalid type="invalid">{errors.finish_point}</Form.Control.Feedback>}*/}
        {/*</Form.Group>*/}
        <Form.Group className="mb-3">
          <Form.Label>Тариф</Form.Label>
          <Form.Select
            isInvalid={errors.tariff_id}
            isValid={!errors.tariff_id}
            required
            name="tariff_id"
            onChange={(e) => {
              handleChange(e);
            }}
          >
            <option value="default" defaultValue>
              Вибрати тариф
            </option>
            {tariffsData?.map((tarriff) => (
              <option
                key={`tarriff-${tarriff.id}`}
                value={tarriff.id}
                selected={formData?.tariff_id === tarriff.id}
              >
                {tarriff.name}
              </option>
            ))}
          </Form.Select>
          {errors.tariff_id && (
            <Form.Control.Feedback type="invalid">
              {errors.tariff_id}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Row className="justify-content-between align-items-center">
            <Col xs={12} md={6}>
              <Form.Label>Кілометраж</Form.Label>
              <Form.Control
                ref={distanceInputRef}
                disabled
                key="distance"
                name="distance"
                type="text"
                placeholder="0"
              />
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Вартість</Form.Label>
              <Form.Control
                ref={priceInputRef}
                disabled
                key="price"
                name="price"
                type="text"
                placeholder="0"
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Водій</Form.Label>
          <Row className="align-items-center">
            {driversData?.map((driver) => {
              return (
                <Col
                  key={`col-${driver.driver_id}`}
                  className="driver-col"
                  xs={12}
                  sm={4}
                  md={3}
                  lg={2}
                >
                  <Form.Check
                    name="drivers"
                    value={driver.driver_id}
                    key={`driver-${driver.driver_id}`}
                    label={driver.fullname}
                    id={`driver-checkbox-${driver.driver_id}`}
                    htmlFor={`driver-checkbox-${driver.id}`}
                    onChange={handleChange}
                  />
                </Col>
              );
            })}
          </Row>
          {errors.drivers && (
            <div className="invalid-feedback simple-text">{errors.drivers}</div>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Номер клієнта</Form.Label>
          <Form.Control
            isInvalid={errors.client_phone}
            isValid={!errors.client_phone}
            maxLength="13"
            required
            defaultValue={formData?.client_phone ? formData.client_phone : ""}
            name="client_phone"
            type="text"
            placeholder="+380999999999"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {errors.client_phone && (
            <Form.Control.Feedback type="invalid">
              {errors.client_phone}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Термін подачі машини (хв):</Form.Label>
          <Form.Control
            isInvalid={errors.delay_minutes}
            isValid={!errors.delay_minutes}
            defaultValue={
              formData?.delay_minutes ? formData.delay_minutes : "0"
            }
            key="delay_minutes"
            name="delay_minutes"
            type="number"
            min="0"
            step="1"
            placeholder="0"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {errors.delay_minutes && (
            <Form.Control.Feedback type="invalid">
              {errors.delay_minutes}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Button variant="primary" className="mt-3" type="submit">
          {pageData.submitText}
        </Button>
      </Form>
    </>
  );
};

export default OrderForm;

// disabled={Object.keys(errors).length !== 0 || startPointData === '' || endPointData === ''}
