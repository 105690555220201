import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faHome,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { useParams } from "react-router-dom";

export default (props) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({
    name: "Заповніть поле",
    label: "Заповніть поле",
    value: "Заповніть поле",
  });

  let apiUrl = "https://api-taxi.logicart.dev/api/";

  const navigate = useNavigate();

  let params,
    id = null;
  let pageData = {};
  if (props.mode === "add") {
    pageData = {
      heading: "Додавання налаштування сайту",
      submitText: "Додати",
    };
    apiUrl += "siteoption";
  } else if (props.mode === "edit") {
    params = useParams();
    id = params.id;
    pageData = {
      heading: "Редагування налаштування сайту",
      submitText: "Оновити",
    };
    apiUrl += "siteoption/" + id;
    useEffect(() => {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
      );

      fetch(apiUrl, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          setFormData(result.site_option);
          setErrors({});
        })
        .catch((error) => console.log("error", error));
    }, []);
  }

  const handleChange = (e) => {
    if (e.target.name === "name" || e.target.name === "label") {
      if (e.target.value.length < 4) {
        setErrors({
          ...errors,
          [e.target.name]: "Поле має бути довше ніж 4 символи",
        });
      } else {
        delete errors[e.target.name];
        setErrors({ ...errors });
      }
    } else {
      if (e.target.value === "") {
        setErrors({ ...errors, [e.target.name]: "Поле не може бути пустим" });
      } else {
        delete errors[e.target.name];
        setErrors({ ...errors });
      }
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errorsMsg = {
      name: "Така назва вже використовується",
      label: "Такий ярлик (label) вже використовується",
    };

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    let formData = new FormData(e.currentTarget);

    formData.append("name", e.target.name.value);
    formData.append("label", e.target.label.value);
    formData.append("value", e.target.value.value);

    fetch(apiUrl, {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    })
      .then((result) =>
        result.json().then((data) => ({ status: result.status, body: data }))
      )
      .then((result) => {
        if (result.status === 200) {
          navigate(`/siteoptions`);
        } else {
          if (result.body.errors) {
            for (const [key] of Object.entries(result.body.errors)) {
              setErrors({ ...errors, [key]: errorsMsg[key] });
            }
          }
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Volt</Breadcrumb.Item>
            <Breadcrumb.Item active>{pageData.heading}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>
            {pageData.heading} {formData?.name}
          </h4>
          <p className="mb-0">Your web analytics dashboard template.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button variant="primary" size="sm" className="mr-1">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Add User
          </Button>
          <ButtonGroup className="ms-2 ms-lg-3 btn-group">
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10{" "}
                  <span className="icon icon-small ms-auto">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Назва</Form.Label>
          <Form.Control
            isInvalid={errors.name}
            isValid={!errors.name}
            required
            defaultValue={formData?.name ? formData.name : ""}
            key="name"
            name="name"
            type="text"
            placeholder="Назва"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {errors.name && (
            <Form.Control.Feedback isInvalid type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Ярлик (label)</Form.Label>
          <Form.Control
            isInvalid={errors.label}
            isValid={!errors.label}
            required
            defaultValue={formData?.label ? formData.label : ""}
            key="label"
            name="label"
            type="text"
            placeholder="Ярлик"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {errors.label && (
            <Form.Control.Feedback isInvalid type="invalid">
              {errors.label}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Значення</Form.Label>
          <Form.Control
            isInvalid={errors.value}
            isValid={!errors.value}
            required
            defaultValue={formData?.value ? formData.value : ""}
            key="value"
            name="value"
            type="text"
            placeholder="Значення"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {errors.value && (
            <Form.Control.Feedback isInvalid type="invalid">
              {errors.value}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Button
          disabled={
            Object.keys(formData).length === 0 ||
            Object.keys(errors).length !== 0
          }
          variant="primary"
          className="mt-3"
          type="submit"
        >
          {pageData.submitText}
        </Button>

        {/*<Form.Group className="mb-3">*/}
        {/*    <Form.Label>First name</Form.Label>*/}
        {/*    <Form.Control required type="text"  />*/}
        {/*    /!*isInvalid isValid*!/*/}
        {/*    <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>*/}
        {/*    <Form.Control.Feedback type="valid">Виглядає добре!</Form.Control.Feedback>*/}
        {/*</Form.Group>*/}
      </Form>
    </>
  );
};
