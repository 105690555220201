import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faEdit,
  faHome,
  faPlus,
  faTrashAlt,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Card,
  Table,
  Nav,
  Pagination,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment-timezone";

const singleDelete = (id, data, setData) => {
  if (!window.confirm("Are you sure ?")) {
    return;
  }
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append(
    "Authorization",
    "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
  );

  fetch("https://api-taxi.logicart.dev/api/order/" + id, {
    method: "DELETE",
    headers: myHeaders,
  })
    .then((result) => {
      setData(data.filter((u) => u.id !== id));
    })
    .catch((error) => console.log("error", error));
};

const Archive = () => {
  const [data, setData] = useState([]);
  const [startData, setStartData] = useState("");
  const [endData, setEndData] = useState("");
  let apiUrl = "https://api-taxi.logicart.dev/api/";

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    fetch("https://api-taxi.logicart.dev/api/orders", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setData(result.orders);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const totalTransactions = data?.length;

  const handleSumbitReportForm = (e) => {
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    let updatedApiUrl = apiUrl;
    updatedApiUrl += "orders";
    updatedApiUrl = new URL(updatedApiUrl);

    if (e.target.start.value) {
      updatedApiUrl.searchParams.append("start", e.target.start.value);
    }

    if (e.target.end.value) {
      updatedApiUrl.searchParams.append("end", e.target.end.value);
    }

    fetch(updatedApiUrl.href, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setData(result.orders);
      })
      .catch((error) => console.log("error", error));
  };

  const TableRow = (props) => {
    const {
      id,
      client_phone,
      start_point,
      finish_point,
      tariff_id,
      request_time,
      completion_time,
      distance,
      price,
      driver_id,
    } = props;

    return (
      <tr>
        <td className="centered">
          <span className="fw-normal">
            {start_point}
            <br></br>
            {finish_point}
          </span>
        </td>
        <td>
          <span className="fw-normal">{client_phone}</span>
        </td>
        <td className="centered">
          <span className="fw-normal">
            {request_time}
            <br></br>
            {completion_time}
          </span>
        </td>
        <td>
          <span className="fw-normal">{tariff_id}</span>
        </td>
        <td className="centered">
          <span className="fw-normal">
            {price} - {distance}
          </span>
        </td>
        <td>
          <span className="fw-normal">{driver_id}</span>
        </td>
        <td>
          <Button as={Link} to={"/orders/" + id} variant="outline-primary">
            <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
          </Button>
          <Button
            data-id={id}
            onClick={() => {
              singleDelete(id, data, setData);
            }}
            variant="outline-danger"
          >
            <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Volt</Breadcrumb.Item>
            <Breadcrumb.Item active>Заявки</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Архів заявок</h4>
          <p className="mb-0">Виберіть початку та кінцеву дати</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            as={Link}
            to={"/orders/add"}
            variant="primary"
            size="sm"
            className="mr-1"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Додати
          </Button>
          <ButtonGroup className="ms-2 ms-lg-3 btn-group">
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={12} md={8}>
            <Form onSubmit={handleSumbitReportForm}>
              <Row className="justify-content-between align-items-center">
                <Col xs={12} md={5}>
                  {/*<InputGroup>*/}
                  {/*    <InputGroup.Text>*/}
                  {/*        <FontAwesomeIcon icon={faCalendar} />*/}
                  {/*    </InputGroup.Text>*/}
                  {/*    <Form.Control type="date" name="start" placeholder="Початкова дата"  min="2022-01-01" max="2025-12-31" required/>*/}
                  {/*</InputGroup>*/}
                  <Form.Group>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={false}
                      onChange={setStartData}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            name="start"
                            value={
                              startData
                                ? moment(startData).format("YYYY-MM-DD")
                                : ""
                            }
                            placeholder="yyyy-mm-dd"
                            onFocus={openCalendar}
                            onChange={() => {}}
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={5}>
                  {/*<InputGroup>*/}
                  {/*    <InputGroup.Text>*/}
                  {/*        <FontAwesomeIcon icon={faCalendar} />*/}
                  {/*    </InputGroup.Text>*/}
                  {/*    <Form.Control type="date" name="end" placeholder="Кінцева дата"  min="2022-01-01" max="2025-12-31"/>*/}
                  {/*</InputGroup>*/}
                  <Form.Group>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={setEndData}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            name="end"
                            value={
                              endData
                                ? moment(endData).format("YYYY-MM-DD")
                                : ""
                            }
                            placeholder="yyyy-mm-dd"
                            onFocus={openCalendar}
                            onChange={() => {}}
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={2}>
                  <Button type="submit" variant="outline-primary">
                    Отримати звіт
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10{" "}
                  <span className="icon icon-small ms-auto">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom centered">
                  Звідки<br></br>Куди
                </th>
                <th className="border-bottom">Номер клієнта</th>
                <th className="border-bottom centered">
                  Час подачі<br></br>Час виконання
                </th>
                <th className="border-bottom">Тариф</th>
                <th className="border-bottom centered">
                  Вартість(грн)<br></br>Відстань(км)
                </th>
                <th className="border-bottom">Водій</th>
                <th className="border-bottom">Дії</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((t) => (
                <TableRow key={`order-${t.id}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev>Previous</Pagination.Prev>
                <Pagination.Item active>1</Pagination.Item>
                <Pagination.Item>2</Pagination.Item>
                <Pagination.Item>3</Pagination.Item>
                <Pagination.Item>4</Pagination.Item>
                <Pagination.Item>5</Pagination.Item>
                <Pagination.Next>Next</Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{totalTransactions}</b> out of <b>25</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};

export default Archive;
