import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { RoutesPath } from "../routesPath";

const getUserInfo = (userObj) => {
  return {
    token: userObj.token,
    login: userObj.user.login,
    fullname: userObj.user.fullname,
    phone_number: userObj.user.phone_number,
    email: userObj.user.email,
  };
};

const Signin = () => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  // if (localStorage.getItem("taxiUser") !== null) {
  //   navigate("/");
  // }

  let apiUrl = "https://api-taxi.logicart.dev/api/";

  const submitHandler = (e) => {
    setError(false);
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    let formData = new FormData(e.currentTarget);

    fetch(`${apiUrl}login`, {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    })
      .then((result) =>
        result.json().then((data) => ({ status: result.status, body: data }))
      )
      .then((result) => {
        console.log(result);
        if (result.status === 200) {
          const userData = getUserInfo(result.body);
          localStorage.setItem("taxiUser", JSON.stringify(userData));
          navigate(`/`);
        }

        if (result.status === 401) {
          setError(true);
        }
      })
      .catch((error) => setError(true));
  };
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link
              as={Link}
              to={RoutesPath.DashboardOverview.path}
              className="text-gray-700"
            >
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> На головну
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Вхід</h3>
                </div>
                {error && (
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h6 className="mb-0 error-text">
                      Логін (email) чи пароль не правильні
                    </h6>
                  </div>
                )}
                <Form onSubmit={submitHandler} className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Email або Логін</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        placeholder="example@company.com"
                        name="email"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Пароль</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Пароль"
                          name="password"
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      {/* <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0"
                        >
                          Запам'ятати мене
                        </FormCheck.Label>
                      </Form.Check> */}
                      {/* <Card.Link className="small text-end">
                        Відновити пароль ?
                      </Card.Link> */}
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    Увійти
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Signin;
