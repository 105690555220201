import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "@themesberg/react-bootstrap";
import { useParams } from "react-router-dom";

const UserForm = (props) => {
  const [formData, setFormData] = useState({});
  const [roleData, setRoleData] = useState([]);
  const [errors, setErrors] = useState({
    login: "Заповніть поле",
    phone_number: "Заповніть поле",
    fullname: "Заповніть поле",
    email: "Заповніть поле",
    role: "Заповніть поле",
  });
  const [pwd, setPwd] = useState("");

  let apiUrl = "https://api-taxi.logicart.dev/api/";

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    fetch("https://api-taxi.logicart.dev/api/roles", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setRoleData(result.roles);
      })
      .catch((error) => console.log("error", error));
  }, []);

  let id = null;
  let pageData = {};

  if (props.mode === "add") {
    pageData = {
      heading: "Додавання Користувача",
      submitText: "Додати",
    };
    apiUrl += "user";
  }

  if (props.mode === "edit") {
    id = params.id;
    pageData = {
      heading: "Редагування Користувача",
      submitText: "Оновити",
    };
    apiUrl += "user/" + id;
  }

  useEffect(() => {
    if (props.mode === "edit") {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
      );

      fetch(apiUrl, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          setFormData(result.user);
          setErrors({});
        })
        .catch((error) => console.log("error", error));
    }
  }, []);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (e) => {
    switch (e.target.name) {
      case "login":
        if (e.target.value.length < 5) {
          setErrors({
            ...errors,
            login: "Логін має бути довший ніж 5 символів",
          });
        } else {
          delete errors.login;
          setErrors({ ...errors });
        }
        break;
      case "phone_number":
        if (e.target.value.length < 13) {
          setErrors({
            ...errors,
            phone_number: "Номер телефону має бути 13 символів",
          });
        } else {
          delete errors.phone_number;
          setErrors({ ...errors });
        }
        break;
      case "password":
        if (e.target.value !== "" && e.target.value.length < 8) {
          setErrors({
            ...errors,
            password: "Номер телефону має бути довший ніж 8 символів",
          });
        } else {
          delete errors.password;
          setErrors({ ...errors });
          setPwd(e.target.value);
        }
        break;
      case "confirmpassword":
        if (e.target.value !== pwd) {
          setErrors({ ...errors, confirmpassword: "Паролі не співпадають" });
        } else {
          delete errors.confirmpassword;
          setErrors({ ...errors });
        }
        break;
      case "fullname":
        if (e.target.value.length < 4) {
          setErrors({
            ...errors,
            fullname: "Номер телефону має бути 4 символи",
          });
        } else {
          delete errors.fullname;
          setErrors({ ...errors });
        }
        break;
      case "email":
        if (!isValidEmail(e.target.value)) {
          setErrors({ ...errors, email: "Введіть коректну адресу" });
        } else {
          delete errors.email;
          setErrors({ ...errors });
        }
        break;
      case "role":
        if (e.target.value === "default") {
          setErrors({ ...errors, role: "Оберіть роль" });
        } else {
          delete errors.role;
          setErrors({ ...errors });
        }
        break;
      default:
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errorsMsg = {
      login: "Логін вже використовується",
      phone_number: "Номер телефону вже використовується",
      fullname: "Ім'я",
      email: "Адреса вже використовується",
      role: "Оберіть роль",
    };

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    let formData = new FormData(e.currentTarget);

    formData.append("login", e.target.login.value);
    if (e.target.password.value) {
      formData.append("password", e.target.password.value);
    }
    formData.append("fullname", e.target.fullname.value);
    formData.append("phone_number", e.target.phone_number.value);
    formData.append("email", e.target.email.value);
    formData.append("role_id", e.target.role.value);

    fetch(apiUrl, {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    })
      .then((result) =>
        result.json().then((data) => ({ status: result.status, body: data }))
      )
      .then((result) => {
        if (result.status === 200) {
          navigate(`/users`);
        } else {
          if (result.body.errors) {
            for (const [key] of Object.entries(result.body.errors)) {
              setErrors({ ...errors, [key]: errorsMsg[key] });
            }
          }
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>
            {pageData.heading} {formData?.login}
          </h4>
        </div>
      </div>

      <div className="table-settings mb-4"></div>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Логін</Form.Label>
          <Form.Control
            isInvalid={errors.login}
            isValid={!errors.login}
            required
            defaultValue={formData?.login ? formData.login : ""}
            key="login"
            name="login"
            type="text"
            placeholder="Логін"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {errors.login && (
            <Form.Control.Feedback type="invalid">
              {errors.login}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Пароль</Form.Label>
          <Form.Control
            isInvalid={errors.password}
            isValid={!errors.password}
            required={props.mode === "edit" ? false : true}
            key="password"
            type="password"
            name="password"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {!errors.password && (
            <Form.Control.Feedback type="valid">
              Якщо бажаєте не змінювати пароль залиште поле пустим!
            </Form.Control.Feedback>
          )}
          {errors.password && (
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Підтвердити пароль</Form.Label>
          <Form.Control
            isInvalid={errors.confirmpassword}
            isValid={!errors.confirmpassword}
            required={props.mode === "edit" ? false : true}
            key="confirmpassword"
            type="password"
            name="confirmpassword"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {errors.confirmpassword && (
            <Form.Control.Feedback type="invalid">
              {errors.confirmpassword}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Ім'я</Form.Label>
          <Form.Control
            isInvalid={errors.fullname}
            isValid={!errors.fullname}
            required
            defaultValue={formData?.fullname ? formData.fullname : ""}
            name="fullname"
            type="text"
            placeholder="Ім'я"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {errors.fullname && (
            <Form.Control.Feedback type="invalid">
              {errors.fullname}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Номер телефону</Form.Label>
          <Form.Control
            isInvalid={errors.phone_number}
            isValid={!errors.phone_number}
            maxLength="13"
            required
            defaultValue={formData?.phone_number ? formData.phone_number : ""}
            name="phone_number"
            type="text"
            placeholder="+380999999999"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {errors.phone_number && (
            <Form.Control.Feedback type="invalid">
              {errors.phone_number}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            isInvalid={errors.email}
            isValid={!errors.email}
            required={props.mode === "edit" ? false : true}
            disabled={props.mode === "edit" ? true : true}
            defaultValue={formData?.email ? formData.email : ""}
            name="email"
            type="email"
            placeholder="Email"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {errors.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Роль</Form.Label>
          <Form.Select
            isInvalid={errors.role}
            isValid={!errors.role}
            required
            name="role"
            onChange={(e) => {
              handleChange(e);
            }}
          >
            <option value="default" defaultValue>
              Вибрати роль
            </option>
            {roleData?.map((role) => (
              <option
                key={`role-${role.id}`}
                value={role.id}
                selected={formData?.role_id === role.id}
              >
                {role.rolename}
              </option>
            ))}
          </Form.Select>
          {errors.role && (
            <Form.Control.Feedback type="invalid">
              {errors.role}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Button
          disabled={
            Object.keys(formData).length === 0 ||
            Object.keys(errors).length !== 0
          }
          variant="primary"
          className="mt-3"
          type="submit"
        >
          {pageData.submitText}
        </Button>
      </Form>
    </>
  );
};
export default UserForm;
