export const RoutesPath = {
  // pages
  DashboardOverview: { path: "/" },
  Signin: { path: "/sign-in" },
  Users: { path: "/users" },
  UserAdd: { path: "/users/add" },
  UserEdit: { path: "/users/:id" },
  Tariffs: { path: "/tariffs" },
  TariffsAdd: { path: "/tariffs/add" },
  TariffsEdit: { path: "/tariffs/:id" },
  SiteOptions: { path: "/siteoptions" },
  SiteOptionAdd: { path: "/siteoption/add" },
  SiteOptionEdit: { path: "/siteoption/:id" },
  Fuels: { path: "/fuels" },
  FuelAdd: { path: "/fuel/add" },
  FuelEdit: { path: "/fuel/:id" },
  Incomes: { path: "/incomes" },
  IncomesAdd: { path: "/income/add" },
  IncomesEdit: { path: "/income/:id" },
  Bonuses: { path: "/bonuses" },
  BonusesAdd: { path: "/bonus/add" },
  BonusesEdit: { path: "/bonus/:id" },
  OrdersActive: { path: "/orders/active" },
  OrdersArchive: { path: "/orders/archive" },
  OrdersAdd: { path: "/orders/add" },
  OrdersEdit: { path: "/orders/:id" },
  Map: { path: "/map" },
  TariffReport: { path: "/reports/tariff" },
  IncomesReport: { path: "/reports/incomes" },
  DriverReport: { path: "/reports/driver" },
  FuelsReport: { path: "/reports/fuels" },

  Transactions: { path: "/transactions" },
  Settings: { path: "/settings" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signup: { path: "/examples/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
