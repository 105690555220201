import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTaxi } from "@fortawesome/free-solid-svg-icons";
import GoogleMapReact from "google-map-react";

const Map = () => {
  const [data, setData] = useState([]);

  const markers = [
    { id: 1, name: "taxi 1", lat: 48.11246387275785, lng: 23.77655971999154 },
    { id: 2, name: "taxi 2", lat: 48.115787549706475, lng: 23.760423550354645 },
    { id: 3, name: "taxi 3", lat: 48.11699089696873, lng: 23.783340344466616 },
    { id: 4, name: "taxi 4", lat: 48.123179094849725, lng: 23.781795392054573 },
  ];

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    fetch("https://api-taxi.logicart.dev/api/tariffs", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setData(result.tariffs);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const TaxiMarker = ({ text }) => (
    <div className="taxi-marker">
      {" "}
      <FontAwesomeIcon icon={faTaxi} className="me-2" /> {text}
    </div>
  );

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDVrcgV_Y79bMzfZHJGGfWW1xdUVklNd14" }}
        defaultCenter={{
          lat: 48.11739098492685,
          lng: 23.76987061273081,
        }}
        defaultZoom={15}
        yesIWantToUseGoogleMapApiInternals
      >
        {markers.map((marker) => (
          <TaxiMarker
            key={`marker-${marker.id}`}
            text={marker.name}
            lat={marker.lat}
            lng={marker.lng}
            icon={{
              url: "https://cdn.mindbowser.com/custom_marker_pin.svg",
            }}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
