import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faHome,
  faPlus,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Card,
  Table,
  Nav,
  Pagination,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment-timezone";

export default () => {
  const [fuelReportData, setFuelReportData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [startData, setStartData] = useState("");
  const [endData, setEndData] = useState("");
  const [driverId, setDriverId] = useState("");
  let apiUrl = "https://api-taxi.logicart.dev/api/";

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    fetch("https://api-taxi.logicart.dev/api/report/fuels", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setFuelReportData(result.fuels);
      })
      .catch((error) => console.log("error", error));

    fetch("https://api-taxi.logicart.dev/api/users?role=3", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setDriverData(result.users);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const totalTransactions = fuelReportData?.length;

  const handleSumbitReportForm = (e) => {
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    let updatedApiUrl = apiUrl;
    updatedApiUrl += "report/fuels";
    updatedApiUrl = new URL(updatedApiUrl);

    if (e.target.start.value) {
      updatedApiUrl.searchParams.append("start", e.target.start.value);
    }

    if (e.target.end.value) {
      updatedApiUrl.searchParams.append("end", e.target.end.value);
    }

    if (e.target.driver.value && e.target.driver.value !== "default") {
      updatedApiUrl.searchParams.append("driver", e.target.driver.value);
    }

    fetch(updatedApiUrl.href, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setFuelReportData(result.fuels);
      })
      .catch((error) => console.log("error", error));
  };

  const TableRow = (props) => {
    const { fullname, phone_number, litres, value } = props;

    return (
      <tr>
        <td>
          <span className="fw-normal">{fullname}</span>
        </td>
        <td>
          <span className="fw-normal">{phone_number}</span>
        </td>
        <td>
          <span className="fw-normal">{litres}</span>
        </td>
        <td>
          <span className="fw-normal">{value}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Volt</Breadcrumb.Item>
            <Breadcrumb.Item active>Заявки</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Звіт по заправках</h4>
          <p className="mb-0">Виберіть початку та кінцеву дати</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            as={Link}
            to={"/orders/add"}
            variant="primary"
            size="sm"
            className="mr-1"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Додати
          </Button>
          <ButtonGroup className="ms-2 ms-lg-3 btn-group">
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={12} md={8}>
            <Form onSubmit={handleSumbitReportForm}>
              <Row className="justify-content-between align-items-center">
                <Col xs={12} md={3}>
                  <Form.Group>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={false}
                      onChange={setStartData}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            name="start"
                            value={
                              startData
                                ? moment(startData).format("YYYY-MM-DD")
                                : ""
                            }
                            placeholder="yyyy-mm-dd"
                            onFocus={openCalendar}
                            onChange={() => {}}
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={setEndData}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            name="end"
                            value={
                              endData
                                ? moment(endData).format("YYYY-MM-DD")
                                : ""
                            }
                            placeholder="yyyy-mm-dd"
                            onFocus={openCalendar}
                            onChange={() => {}}
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={3}>
                  <Form.Group className="">
                    <Form.Select
                      name="driver"
                      onChange={(e) => {
                        setDriverId(e.target.value);
                      }}
                    >
                      <option value="default" defaultValue>
                        Оберіть водія
                      </option>
                      {driverData?.map((driver, index) => (
                        <option
                          key={`driver-${index}`}
                          value={driver.id}
                          selected={driverId === driver.id}
                        >
                          {driver.fullname}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} md={2}>
                  <Button type="submit" variant="outline-primary">
                    Отримати звіт
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10{" "}
                  <span className="icon icon-small ms-auto">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Ім'я водія</th>
                <th className="border-bottom">Номер водія</th>
                <th className="border-bottom">Заправка (л)</th>
                <th className="border-bottom">Сума (грн)</th>
              </tr>
            </thead>
            <tbody>
              {fuelReportData?.map((t, index) => (
                <TableRow key={`report-fuel-${index}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev>Previous</Pagination.Prev>
                <Pagination.Item active>1</Pagination.Item>
                <Pagination.Item>2</Pagination.Item>
                <Pagination.Item>3</Pagination.Item>
                <Pagination.Item>4</Pagination.Item>
                <Pagination.Item>5</Pagination.Item>
                <Pagination.Next>Next</Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{totalTransactions}</b> out of <b>25</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};
