import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup, Card, Table } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

const singleDelete = (id, data, setData) => {
  if (!window.confirm("Are you sure ?")) {
    return;
  }
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append(
    "Authorization",
    "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
  );

  fetch("https://api-taxi.logicart.dev/api/user/" + id, {
    method: "DELETE",
    headers: myHeaders,
  })
    .then((result) => {
      setData(data.filter((u) => u.id !== id));
    })
    .catch((error) => console.log("error", error));
};

const UsersTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    fetch("https://api-taxi.logicart.dev/api/users", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setData(result.users);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const TableRow = (props) => {
    const { id, login, fullname, email, rolename, phone_number } = props;

    return (
      <tr>
        <td>
          <span className="fw-normal">{id}</span>
        </td>
        <td>
          <span className="fw-normal">{login}</span>
        </td>
        <td>
          <span className="fw-normal">{fullname}</span>
        </td>
        <td>
          <span className="fw-normal">{email}</span>
        </td>
        <td>
          <span className="fw-normal">{rolename}</span>
        </td>
        <td>
          <span className="fw-normal">{phone_number}</span>
        </td>
        <td>
          <ButtonGroup className="action-group">
            <Button as={Link} to={"/users/" + id} variant="outline-primary">
              <FontAwesomeIcon icon={faEdit} className="me-2" />
            </Button>
            <Button
              as={Link}
              data-id={id}
              onClick={() => {
                singleDelete(id, data, setData);
              }}
              variant="outline-danger"
            >
              <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">ID</th>
              <th className="border-bottom">Логін</th>
              <th className="border-bottom">Ім'я</th>
              <th className="border-bottom">Email</th>
              <th className="border-bottom">Роль</th>
              <th className="border-bottom">Телефон</th>
              <th className="border-bottom">Дії</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((t) => (
              <TableRow key={`user-${t.id}`} {...t} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const Users = () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Керування Користувачами</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            as={Link}
            to={"/users/add"}
            variant="primary"
            size="sm"
            className="mr-1"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Додати
          </Button>
        </div>
      </div>

      <UsersTable />
    </>
  );
};

export default Users;
