import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { RoutesPath } from "../routesPath";

// pages
import Signin from "./Signin";
import Archive from "./Archive";
import Users from "./Users";
import UserForm from "./components/UserForm";
import Tariffs from "./Tariffs";
import TariffForm from "./components/TariffForm";
import SiteOptions from "./SiteOptions";
import SiteOptionForm from "./components/SiteOptionForm";
import Fuels from "./Fuels";
import FuelForm from "./components/FuelForm";
import Incomes from "./Incomes";
import IncomeForm from "./components/IncomeForm";
import Bonuses from "./Bonuses";
import BonusesForm from "./components/BonusesForm";
import Orders from "./Orders";
import Map from "./Map";
import Upgrade from "./Upgrade";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// components
import Sidebar from "../components/Sidebar";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import OrderForm from "./components/OrderForm";
import FuelsReport from "./FuelsReport";
import IncomesReport from "./IncomesReport";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const userData = localStorage.getItem("taxiUser")
    ? JSON.parse(localStorage.getItem("taxiUser"))
    : {};
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return userData?.token ? (
    <>
      {" "}
      <Preloader show={loaded ? false : true} /> <Outlet />{" "}
    </>
  ) : (
    <Navigate to={RoutesPath.Signin.path} />
  );
};

const RouteWithSidebar = (...rest) => {
  const [loaded, setLoaded] = useState(false);
  const userData = localStorage.getItem("taxiUser")
    ? JSON.parse(localStorage.getItem("taxiUser"))
    : {};

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return userData?.token ? (
    <>
      <Preloader show={loaded ? false : true} />
      <Sidebar />

      <main className="content">
        {!rest.fullpage && <NavigationBar />}
        <Outlet />
        {!rest.fullpage && (
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        )}
      </main>
    </>
  ) : (
    <Navigate to={RoutesPath.Signin.path} />
  );
};

const HomePage = () => (
  <Routes>
    <Route
      path="/"
      element={<Navigate replace to={RoutesPath.OrdersActive.path} />}
    />
    <Route path="/" element={<RouteWithSidebar />}>
      {/* pages */}
      <Route path={RoutesPath.Users.path} element={<Users />} />
      <Route path={RoutesPath.UserAdd.path} element={<UserForm mode="add" />} />
      <Route
        path={RoutesPath.UserEdit.path}
        element={<UserForm mode="edit" />}
      />
      <Route path={RoutesPath.Tariffs.path} element={<Tariffs />} />
      <Route
        path={RoutesPath.TariffsAdd.path}
        element={<TariffForm mode="add" />}
      />
      <Route
        path={RoutesPath.TariffsEdit.path}
        element={<TariffForm mode="edit" />}
      />
      <Route path={RoutesPath.SiteOptions.path} element={<SiteOptions />} />
      <Route
        path={RoutesPath.SiteOptionAdd.path}
        element={<SiteOptionForm mode="add" />}
      />
      <Route
        path={RoutesPath.SiteOptionEdit.path}
        element={<SiteOptionForm mode="edit" />}
      />
      <Route path={RoutesPath.Fuels.path} element={<Fuels />} />
      <Route path={RoutesPath.FuelAdd.path} element={<FuelForm mode="add" />} />
      <Route
        path={RoutesPath.FuelEdit.path}
        element={<FuelForm mode="edit" />}
      />
      <Route path={RoutesPath.Incomes.path} element={<Incomes />} />
      <Route
        path={RoutesPath.IncomesAdd.path}
        element={<IncomeForm mode="add" />}
      />
      <Route
        path={RoutesPath.IncomesEdit.path}
        element={<IncomeForm mode="edit" />}
      />
      <Route path={RoutesPath.Bonuses.path} element={<Bonuses />} />
      <Route
        path={RoutesPath.BonusesAdd.path}
        element={<BonusesForm mode="add" />}
      />
      <Route
        path={RoutesPath.BonusesEdit.path}
        element={<BonusesForm mode="edit" />}
      />
      <Route index path={RoutesPath.OrdersActive.path} element={<Orders />} />
      <Route path={RoutesPath.OrdersArchive.path} element={<Archive />} />
      <Route
        path={RoutesPath.OrdersAdd.path}
        element={<OrderForm mode="add" />}
      />
      <Route
        path={RoutesPath.OrdersEdit.path}
        element={<OrderForm mode="edit" />}
      />
      <Route path={RoutesPath.Map.path} fullpage={true} element={<Map />} />
      <Route path={RoutesPath.TariffReport.path} element={<FuelsReport />} />
      <Route path={RoutesPath.FuelsReport.path} element={<FuelsReport />} />
      <Route path={RoutesPath.IncomesReport.path} element={<IncomesReport />} />
      <Route path={RoutesPath.Upgrade.path} element={<Upgrade />} />
      <Route path={RoutesPath.Transactions.path} element={<Transactions />} />
      <Route path={RoutesPath.Settings.path} element={<Settings />} />
      <Route
        path={RoutesPath.BootstrapTables.path}
        element={<BootstrapTables />}
      />
    </Route>
    <Route path={RoutesPath.Signin.path} element={<Signin />} />
    <Route path="/" element={<RouteWithLoader />}>
      <Route path={RoutesPath.Signup.path} element={<Signup />} />
      <Route
        path={RoutesPath.ForgotPassword.path}
        element={<ForgotPassword />}
      />
      <Route path={RoutesPath.ResetPassword.path} element={<ResetPassword />} />
      <Route path={RoutesPath.Lock.path} element={<Lock />} />
      <Route path={RoutesPath.ServerError.path} element={<ServerError />} />
      <Route path={RoutesPath.ServerError.path} element={<ServerError />} />
    </Route>
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default HomePage;
