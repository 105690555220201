import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faEdit,
  faHome,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Card,
  Table,
  Nav,
  Pagination,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

const singleDelete = (id, data, setData) => {
  if (!window.confirm("Are you sure ?")) {
    return;
  }
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append(
    "Authorization",
    "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
  );

  fetch("https://api-taxi.logicart.dev/api/tariff/" + id, {
    method: "DELETE",
    headers: myHeaders,
  })
    .then((result) => {
      setData(data.filter((u) => u.id !== id));
    })
    .catch((error) => console.log("error", error));
};

const TtariffTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 2|Okdi7w8cO1KAu0qSTR7wHVtxg94MBu7BVj9M043j"
    );

    fetch("https://api-taxi.logicart.dev/api/tariffs", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setData(result.tariffs);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const totalTransactions = data?.length;

  const TableRow = (props) => {
    const { id, name, start_price, price_per_km, pause_price, minimal_price } =
      props;

    return (
      <tr>
        <td>
          <span className="fw-normal">{id}</span>
        </td>
        <td>
          <span className="fw-normal">{name}</span>
        </td>
        <td>
          <span className="fw-normal">{start_price}</span>
        </td>
        <td>
          <span className="fw-normal">{price_per_km}</span>
        </td>
        <td>
          <span className="fw-normal">{pause_price}</span>
        </td>
        <td>
          <span className="fw-normal">{minimal_price}</span>
        </td>
        <td>
          <Button as={Link} to={"/tariffs/" + id} variant="outline-primary">
            <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
          </Button>
          <Button
            data-id={id}
            onClick={() => {
              singleDelete(id, data, setData);
            }}
            variant="outline-danger"
          >
            <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">ID</th>
              <th className="border-bottom">Назва</th>
              <th className="border-bottom">Ціна за посадку</th>
              <th className="border-bottom">Ціна за кілометр</th>
              <th className="border-bottom">Ціна за хв. простою</th>
              <th className="border-bottom">Мінімальна вартість</th>
              <th className="border-bottom">Дії</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((t) => (
              <TableRow key={`tariff-${t.id}`} {...t} />
            ))}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export default () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Volt</Breadcrumb.Item>
            <Breadcrumb.Item active>Тарифи</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Керування Тарифами</h4>
          <p className="mb-0">Your web analytics dashboard template.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            as={Link}
            to={"/tariffs/add"}
            variant="primary"
            size="sm"
            className="mr-1"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Додати
          </Button>
          <ButtonGroup className="ms-2 ms-lg-3 btn-group">
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10{" "}
                  <span className="icon icon-small ms-auto">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <TtariffTable />
    </>
  );
};
